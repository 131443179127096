import { Box, Card, CardHeader, Heading, CardBody, VStack,
  Button, Text, Input } from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";

import { useNavigate } from "react-router-dom";

function NewDonor() {
  const { appState, setAppState } = useAppStateStore();
  const navigate = useNavigate();
  
  const updateDonor = (evt) => {
    const donor = { ...appState.donor, [evt.target.name]: evt.target.value, };
    setAppState({ ...appState, donor: donor, });
  };
  
  const submitDonor = () => {
  
    if (!appState.donor.name) {
      alert('Please enter at least a donor name');
      return false;
    }
  
    const donor = { ...appState.donor, loading: true, };
    setAppState({ ...appState, donor: donor, });
    
    fetch(appState.endpoint + 'donor', {
      method : "POST",
      body   : JSON.stringify({...appState.donor, books: undefined, })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          const donor = { ...appState.donor, id : data.id, loading: false, };
          setAppState({
            ...appState,
            donor   : donor,
            loading : false,
          });
          navigate('/entry')
        }
        else {
          const donor = { ...appState.donor, loading: false, };
          setAppState({
            ...appState,
            donor   : donor,
            loading : false,
          });
          alert('Error saving data. Please try again.');
        }
      })      
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });    
  };
  
  return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pt='50'
        mt='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Enter New Donor Info</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={3} align='stretch'>
              <Text size='sm'>Donor Name:</Text>
              <Input variant='outline' placeholder='Donor Name' name='name' value={appState.donor.name} onChange={updateDonor} /> 
              
              <Text size='sm'>In Memory of (optional):</Text>
              <Input variant='outline' placeholder='In Memory of' name='memory' value={appState.donor.memory} onChange={updateDonor} />
              
              <Text size='sm'>Address: </Text>
              <Input variant='outline' placeholder='Street Address or PO Box' name='address' value={appState.donor.address} onChange={updateDonor} />
              
              <Text size='sm'>Apt or Suite#:</Text>
              <Input variant='outline' placeholder='Address line 2' name='apartment' value={appState.donor.apartment} onChange={updateDonor} />
              
              <Text size='sm'>Town:</Text>
              <Input variant='outline' placeholder='Town' name='town' value={appState.donor.town} onChange={updateDonor} />
              
              <Text size='sm'>State:</Text>
              <Input variant='outline' placeholder='State' name='state' value={appState.donor.state} onChange={updateDonor} />
              
              <Text size='sm'>Zip Code:</Text>
              <Input variant='outline' placeholder='00000' name='zip' value={appState.donor.zip} onChange={updateDonor} />
              
              <Text size='sm'>Email:</Text>
              <Input variant='outline' placeholder='example@example.com' name='email' value={appState.donor.email} onChange={updateDonor} />
              
              <Text size='sm'>Phone:</Text>
              <Input variant='outline' placeholder='(000) 000-0000' name='phone' value={appState.donor.phone} onChange={updateDonor} />
              
              <Button colorScheme='blue' size='lg' onClick={submitDonor} isLoading={appState.donor.loading} loadingText='Submitting' style={{marginTop:15}}>Continue</Button>
            </VStack>
          </CardBody>
        </Card>      
      </Box>  
  );
}

export default NewDonor;