import { Box, Card, CardHeader, Heading, CardBody, VStack,
  Button, Text, Input, Select } from '@chakra-ui/react';
import { useAppStateStore, emptyDonor, emptyBook } from "./AppStateProvider";
import { useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

function UserFeedback() {
  const { appState } = useAppStateStore();

  if (appState.error) {
    return (    
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{appState.error}</AlertDescription>
      </Alert>              
    );
  }
  else {
    return (<></>);
  }
}

function DonorChoices() {
  const { appState, setAppState } = useAppStateStore();
  const navigate = useNavigate();
  
  const setChoice = (evt) => {
    if ('New' === evt.target.value) {
      setAppState({
        ...appState,
        donor : emptyDonor,
      });
      navigate('/donor');
    }
    else {
      const d = appState.donors.reduce((prev, curr) => {
        if (curr.id === evt.target.value) {
          return curr;
        }
        else {
          return prev;
        }
      }, '');
      
      setAppState({ ...appState, donor : d, });
    }
  };
  
  const choices = appState.donors.map((d) => {
    let nameLocation = d.name;
    if (d.state && d.town) { nameLocation += ` (${d.town}, ${d.state})`}
    return (<option value={d.id} key={d.id}>{nameLocation}</option>);
  });
  
  return (
    <Select placeholder='Select Donor' onChange={setChoice} value={appState.donor.id}>
      <option value='New' key='new'>Enter new donor</option>
      {choices}
    </Select>  
  )
}

function Entry() {
  const { appState, setAppState } = useAppStateStore();

  const updateField = (evt) => {
    const book = { ...appState.book, [evt.target.name]: evt.target.value, };
    setAppState({ ...appState, book: book });
  };
  
  useEffect(() => {
    fetch(appState.endpoint + 'donor', {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        // console.log(data.donors);
        setAppState({ 
          ...appState, 
          donors : data.donors.sort((a,b) => {
            return (a.name.toLowerCase() === b.name.toLowerCase()) ? 0 :
            (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
          }), 
          loading : false,
        });
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading : false, });
    });    
  }, []);
  
  const submit = () => {
    setAppState({ ...appState, loading: true, error: false, });
  
    fetch(appState.endpoint + 'login', {
      method : "POST",
      body   : JSON.stringify({
        user     : appState.user,
        password : appState.password,
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          setAppState({
            ...appState,
            loading : false,
            error   : false, 
            token   : data.token,
          });
        }
        else {
          const msg = data.message ? data.message : 'Unsuccessful Authentication';
          setAppState({ ...appState, error: msg, });
        }
      })
    })
  };

  const saveBook = () => {
    setAppState({ ...appState, loading: true, error: false, });

    const book = { ...appState.book, donor : appState.donor.id, };

    fetch(appState.endpoint + 'books', {
      method : 'POST',
      body   : JSON.stringify(book)
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          alert('Book Saved');
          const book = { ...emptyBook, donor: appState.donor.id, };
          setAppState({
            ...appState,
            book    : book,
            status  : 'enter',
            loading : false,
          });
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('Error saving data. Please try again.');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });    

  };

  const onChangeAuthors = (evt) => {
    const text    = evt.target.value;
    const authors = text.split(', ');
    const book    = { ...appState.book, authors: authors }
    setAppState({ ...appState, book: book });
  };

  const onChangeWeight = (evt) => {
    const text = evt.target.value;
    if (appState.book.dimensions) {
      const weight = { ...appState.book.dimensions.weight, value: text, };
      const dimensions = { ...appState.book.dimensions, weight: weight };
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
    else {
      const dimensions = {
        width     : { value: 0, unit: 'inches' }, 
        thickness : { value: 0, unit: 'inches' },
        weight    : { value: text, unit: 'pounds' },
        height    : { value: 0, unit: 'inches'}
      }
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
  };

  const onChangeHeight = (evt) => {
    const text = evt.target.value;
    if (appState.book.dimensions) {
      const height = { ...appState.book.dimensions.height, value: text, };
      const dimensions = { ...appState.book.dimensions, height: height };
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
    else {
      const dimensions = {
        width     : { value: 0, unit: 'inches' }, 
        thickness : { value: 0, unit: 'inches' },
        weight    : { value: 0, unit: 'pounds' },
        height    : { value: text, unit: 'inches'}
      }
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
  };

  const onChangeWidth = (evt) => {
    const text = evt.target.value;
    if (appState.book.dimensions) {
      const width      = { ...appState.book.dimensions.width, value: text, };
      const dimensions = { ...appState.book.dimensions, width: width };
      const book       = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
    else {
      const dimensions = {
        width     : { value: text, unit: 'inches' }, 
        thickness : { value: 0, unit: 'inches' },
        weight    : { value: 0, unit: 'pounds' },
        height    : { value: 0, unit: 'inches'}
      }
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
  };

  const onChangeThickness = (evt) => {
    const text = evt.target.value;
    if (appState.book.dimensions) {
      const thickness = { ...appState.book.dimensions.thickness, value: text, };
      const dimensions = { ...appState.book.dimensions, thickness: thickness };
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
    else {
      const dimensions = {
        width : { value: 0, unit: 'inches' }, 
        thickness : { value: text, unit: 'inches' },
        weight : { value: 0, unit: 'pounds' },
        height : { value: 0, unit: 'inches'}
      }
      const book = { ...appState.book, dimensions: dimensions };
      setAppState({ ...appState, book: book, });
    }
  };
  
  const conditionOptions = [
    'Select condition', 'New', 'Used, Excellent', 'Used, Good', 'Used, Fair', 'Used, Poor'
  ];
  const setCondition = (evt) => {
    const book = { ...appState.book, condition : conditionOptions[evt.target.selectedIndex], };
    setAppState({ ...appState, book: book, });
  };  
  
  if (appState.token && appState.user) {
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pt='50'
        mt='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
  
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Enter Donated Book</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={3} align='stretch'>
              <Text size='sm'>Who Donated the Book?</Text>
              <DonorChoices />
            
              <Text size='sm'>Book Title:</Text>
              <Input variant='outline' placeholder='Book Title:' name='title' value={appState.book.title} onChange={updateField} />
  
              <Text size='sm' style={{marginTop:12}}>Edition:</Text>
              <Input variant='outline' placeholder='Edition:' name='edition' value={appState.book.edition} onChange={updateField} />
  
              <Text size='sm' style={{marginTop:12}}>Author(s):</Text>
              <Input variant='outline' placeholder='Author(s) as First Last, First Last' name='edition' value={appState.book.authors.join(', ')} onChange={onChangeAuthors} />
              
              <Text size='sm' style={{marginTop:12}}>Binding:</Text>
              <Input variant='outline' placeholder='Binding:' name='binding' value={appState.book.binding} onChange={updateField} />
              
              <Text size='sm' style={{marginTop:12}}>Publisher:</Text>
              <Input variant='outline' placeholder='Publisher:' name='publisher' value={appState.book.publisher} onChange={updateField} />
                          
              <Text size='sm' style={{marginTop:12}}>Year Published:</Text>
              <Input variant='outline' placeholder='Year Published:' name='date_published' value={appState.book.date_published} onChange={updateField} />
              
              <Text size='sm' style={{marginTop:12}}>Description:</Text>
              <Input variant='outline' placeholder='Description:' name='description' value={appState.book.description} onChange={updateField} />
              
              <Text size='sm' style={{marginTop:12}}>ISBN:</Text>
              <Input variant='outline' placeholder='ISBN:' name='isbn' value={appState.book.isbn} onChange={updateField} />
              
              <Text size='sm' style={{marginTop:12}}>Weight:</Text>
              <Input variant='outline' placeholder='Pounds. Use decimal values (0.5 not 1/2)' name='weight' value={(appState.book.dimensions && appState.book.dimensions.weight) ? String(appState.book.dimensions.weight.value) : ''} onChange={onChangeWeight} />
              
              <Text size='sm' style={{marginTop:12}}>Height:</Text>
              <Input variant='outline' placeholder='Height: Inches. Use decimal values (0.5 not 1/2)' name='height' value={(appState.book.dimensions && appState.book.dimensions.height) ? String(appState.book.dimensions.height.value) : ''} onChange={onChangeHeight} />
              
              <Text size='sm' style={{marginTop:12}}>Width:</Text>
              <Input variant='outline' placeholder='Width: Inches. Use decimal values (0.5 not 1/2)' name='height' value={(appState.book.dimensions && appState.book.dimensions.width) ? String(appState.book.dimensions.width.value) : ''} onChange={onChangeWidth} />
              
              <Text size='sm' style={{marginTop:12}}>Thickness:</Text>
              <Input variant='outline' placeholder='Thickness: Inches. Use decimal values (0.5 not 1/2)' name='height' value={(appState.book.dimensions && appState.book.dimensions.thickness) ? String(appState.book.dimensions.thickness.value) : ''} onChange={onChangeThickness} />
              
              <Text size='sm' style={{marginTop:12}}>Condition:</Text>
              <Select placeholder='Select condition' defaultValue={appState.book.condition} onChange={setCondition}>
                <option value='New'>New</option>
                <option value='Used, Excellent'>Used, Excellent</option>
                <option value='Used, Good'>Used, Good</option>
                <option value='Used, Fair'>Used, Fair</option>
                <option value='Used, Poor'>Used, Poor</option>
              </Select>
              
              <Text size='sm' style={{marginTop:12}}>Price:</Text>
              <Input variant='outline' placeholder='Price:' name='price' value={appState.book.price} onChange={updateField} />
  
              <Button colorScheme='blue' size='lg' onClick={saveBook} isLoading={appState.loading} loadingText='Submitting' style={{marginTop:15}}>Save Book</Button>
            </VStack>
          </CardBody>
  
        </Card>
    
      </Box>
    );  
  }
  else {
    const updateLogin = (evt) => {
      setAppState({ ...appState, [evt.target.name]: evt.target.value, });
    };
    
    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pt='50'
        mt='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Login</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={3} align='stretch'>
              <Text size='sm'>User email:</Text>
              <Input variant='outline' placeholder='User email:' name='user' value={appState.user} onChange={updateLogin} />
              
              <Text size='sm' style={{marginTop:12}}>Password:</Text>
              <Input type='password' variant='outline' name='password' value={appState.password} onChange={updateLogin} />
              
              <Button colorScheme='blue' size='lg' onClick={submit} isLoading={appState.loading} loadingText='Submitting' style={{marginTop:15}}>Continue</Button>
              
              <UserFeedback />
            </VStack>
          </CardBody>        
        </Card>
      </Box>
    );    
  }
}

export default Entry;